import React, {
  createContext,
  useReducer,
  useContext,
  ReactNode,
} from "react";
import { ProductCardType } from "../types";


export type CartProductType = ProductCardType & {
  quantity: number;
  totalPrice: number;
};

// Define Cart State type
type CartState = CartProductType[];

// Define Cart Action types
type CartAction =
  | { type: "ADD_TO_CART"; payload: CartProductType }
  | { type: "REMOVE_CART_PRODUCT_QUANTITY"; payload: { id: number } }
  | { type: "REMOVE_FROM_CART"; payload: { id: number } }
  | { type: "CLEAR_CART" }
  | { type: "SET_CART"; payload: CartProductType[] };

// Define Cart Context type
interface CartContextType {
  cart: CartState;
  addToCart: (product: CartProductType) => void;
  removeFromCart: (product: { id: number }) => void;
  removeCartProductQuantity: (product: { id: number }) => void;
  clearCart: () => void;
}

// Create Cart Context
const CartContext = createContext<CartContextType | undefined>(undefined);

// Cart Reducer function
const cartReducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case "ADD_TO_CART":
      const product = state.find((product) => product.id == action.payload.id);
      if (product) {
        return state.map((oldProduct) => {
          if (oldProduct.id === product.id) {
            const totalPrice =
              action.payload.quantity > 1
                ? product.price * action.payload.quantity
                : oldProduct.totalPrice;
            return {
              ...oldProduct,
              totalPrice,
              quantity:
                action.payload.quantity
            };
          }
          return oldProduct;
        })

      }

      return [
        ...state,
        { ...action.payload, totalPrice: action.payload.price * action.payload.quantity },
      ];
    case "REMOVE_FROM_CART":
      return state.filter((item) => item.id !== action.payload.id);
    case "REMOVE_CART_PRODUCT_QUANTITY":
      const findProduct = state.find(
        (productCart) => productCart.id === action.payload.id
      );
      if (findProduct?.quantity === 1) {
        return state;
      }

      return state.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            quantity: (item.quantity -= 1),
            totalPrice: item.totalPrice - item.price,
          };
        }
        return item;
      });
    case "CLEAR_CART":
      return [];
    case "SET_CART":
      return action.payload;
    default:
      return state;
  }
};

// Cart Provider Component Props type
interface CartProviderProps {
  children: ReactNode;
}

// Cart Provider Component
export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, []);
 
  const addToCart = (product: CartProductType) => {
    dispatch({ type: "ADD_TO_CART", payload: product });
  };

  const removeFromCart = (product: { id: number }) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: product });
  };

  const removeCartProductQuantity = (product: { id: number }) => {
    dispatch({ type: "REMOVE_CART_PRODUCT_QUANTITY", payload: product });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        removeCartProductQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use Cart Context
export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
