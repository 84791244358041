"use client";
import { createContext, useContext, useState, ReactNode, FC } from "react";

// Define the shape of the AuthContext state
interface AuthContextType {
  user: boolean | null;
  login: (token: string) => void;
  logout: () => void;
}

// Create the AuthContext with a default value of null
const AuthContext = createContext<AuthContextType | null>(null);

// Function to get authentication status from localStorage
const getAuth = (): boolean | null => {
  if (typeof window !== "undefined" && localStorage.getItem("access_token")) {
    return true;
  } else {
    return null;
  }
};

// Define the AuthProvider props type
interface AuthProviderProps {
  children: ReactNode;
}

// AuthProvider component providing the AuthContext
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<boolean | null>(getAuth());
  // const router = useRouter();

  const login = (token: string) => {
    localStorage.setItem("access_token", token);
    setUser(true);
    // router.push("/admin");
};

  const logout = () => {
    localStorage.removeItem("access_token");
    setUser(false);
    // router.push("/login");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("Wrap <App /> component with <AuthProvider>!");
  }

  return context;
};
