import { useAuth } from "./authContext";
import { PropsWithChildren, ReactElement, useEffect } from "react";
import { getTokenHandler } from "../utils/apiUtils";
import { useNavigate } from "react-router-dom";

function ProtectedRoute({ children }: PropsWithChildren) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const getToken = async (token: string) => {
      const data = await getTokenHandler(token);
      if (data.error) {
        logout();
        navigate('/')
      }
    };
    const token =  localStorage.getItem('access_token')
    if (token) {
        getToken(token);
    }
  }, [user]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate('/')
    }
  }, []);

  return children as ReactElement;
}

export default ProtectedRoute;
