import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ProtectedRoute from "./context/ProtectedRoute";
import Helmet from "react-helmet";
import { lazy, Suspense } from "react";
import Loader from "./components/Loader/Loader";
const Home = lazy(() => import("./screens/Home/Home"));
const Products = lazy(() => import("./screens/Products/Products"));
const Product = lazy(() => import("./screens/Product/Product"));
const Cart = lazy(() => import("./screens/Cart/Cart"));
const Login = lazy(() => import("./screens/Login/Login"));
const Member = lazy(() => import("./screens/Member/Member"));
const Categories = lazy(() => import("./screens/Categories/Categories"));
const Dashboard = lazy(() => import("./screens/Admin/Admin"));

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route
        key="firstRoute"
        path="/"
        element={
          <>
            <Helmet>
              <title>Utvo - Market domacih proizvoda </title>
              <meta
                name="description"
                content="Utvo - Market domacih proizvoda"
              />
              <meta
                name="keywords"
                content="utvo market, utvo, voće, povrće, mlečni proizvodi, meso, zadruga, market, prodavnica, utvo, market, utvomarket, утвомаркет, воће, поврће, пољопривреда, домаће, утво, маркет, domaci proizvodi, domaci proizvodi kragujevac, kragujevac, zadruga market"
              />
            </Helmet>
            <Home />
          </>
        }
      />,
      <Route
        path="/products"
        element={
          <>
            <Helmet>
              <title>Utvo - Market domacih proizvoda </title>
              <meta
                name="description"
                content="Utvo - Market domacih proizvoda"
              />
              <meta
                name="keywords"
                content="utvo market, utvo, voće, povrće, mlečni proizvodi, meso, zadruga, market, prodavnica, utvo, market, utvomarket, утвомаркет, воће, поврће, пољопривреда, домаће, утво, маркет, domaci proizvodi, domaci proizvodi kragujevac, kragujevac, zadruga market"
              />
            </Helmet>
            <Products />
          </>
        }
      />,
      <Route
        path="/products/:id"
        element={
          <>
            <Helmet>
              <title>Utvo - Market domacih proizvoda </title>
              <meta
                name="description"
                content="Utvo - Market domacih proizvoda"
              />
              <meta
                name="keywords"
                content="utvo market, utvo, voće, povrće, mlečni proizvodi, meso, zadruga, market, prodavnica, utvo, market, utvomarket, утвомаркет, воће, поврће, пољопривреда, домаће, утво, маркет, domaci proizvodi, domaci proizvodi kragujevac, kragujevac, zadruga market"
              />
            </Helmet>
            <Product />
          </>
        }
      />,
      <Route
        path="/cart"
        element={
          <>
            <Helmet>
              <title>Utvo - Market domacih proizvoda </title>
              <meta
                name="description"
                content="Utvo - Market domacih proizvoda"
              />
              <meta
                name="keywords"
                content="utvo market, utvo, voće, povrće, mlečni proizvodi, meso, zadruga, market, prodavnica, utvo, market, utvomarket, утвомаркет, воће, поврће, пољопривреда, домаће, утво, маркет, domaci proizvodi, domaci proizvodi kragujevac, kragujevac, zadruga market"
              />
            </Helmet>
            <Cart />
          </>
        }
      />,
      <Route path="/login" element={<Login />} />,
      <Route
        path="/member"
        element={
          <>
            <Helmet>
              <title>Utvo - Market domacih proizvoda </title>
              <meta
                name="description"
                content="Utvo - Market domacih proizvoda"
              />
              <meta
                name="keywords"
                content="utvo market, utvo, voće, povrće, mlečni proizvodi, meso, zadruga, market, prodavnica, utvo, market, utvomarket, утвомаркет, воће, поврће, пољопривреда, домаће, утво, маркет, domaci proizvodi, domaci proizvodi kragujevac, kragujevac, zadruga market"
              />
            </Helmet>
            <Member />
          </>
        }
      />,
      <Route
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
        path="/admin"
      />,
      <Route
        element={
          <ProtectedRoute>
            <Categories />
          </ProtectedRoute>
        }
        path="/admin/categories"
      />,
      // <Route key="cacheAllRoute" element={<Fallback />} path="*" />,
    ])
  );

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
