import axios from "axios";
export const IMG_PREFIX = 'https://utvomarket.rs'
const getHandler = async ({
  endpoint,
  params,
}: {
  endpoint: string;
  params: any;
}) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BASE_URL}${endpoint}`,
    {
      params,
    }
  );

  return data;
};

export const getTokenHandler = async (token: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BASE_URL}user/auth`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return data;
};

const postHandler = async ({
  endpoint,
  body,
}: {
  endpoint: string;
  body: any;
}) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BASE_URL}${endpoint}`,
    body,
    {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    }
  );

  return data;
};

const patchHandler = async ({
  endpoint,
  body,
}: {
  endpoint: string;
  body: any;
}) => {
  const { data } = await axios.put(
    `${process.env.REACT_APP_BASE_URL}${endpoint}`,
    body,
    {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    }
  );
  return data;
};

const deleteHandler = async ({ endpoint }: { endpoint: string }) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}${endpoint}`,
    {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    }
  );
  return data;
};

export { postHandler, getHandler, deleteHandler, patchHandler };
